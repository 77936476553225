<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Views.Versions.Title") }}</h1>
        <table class="table table-sm table-striped table-dark mt-5">
          <caption>
            {{
              $t("Copla.Views.Versions.Table.Updated")
            }}:
            {{
              updateTable
            }}
          </caption>
          <thead>
            <tr>
              <th>{{ $t("Copla.Views.Versions.Table.Version") }}</th>
              <th>{{ $t("Copla.Views.Versions.Table.Date") }}</th>
              <th>{{ $t("Copla.Views.Versions.Table.Characters") }}</th>
              <th>{{ $t("Copla.Views.Versions.Table.Lines") }}</th>
              <th>{{ $t("Copla.Views.Versions.Table.Subtitles") }}</th>
              <th>{{ $t("Copla.Views.Versions.Table.Transcription") }}</th>
              <th>
                <span class="reload" v-on:click="getVersions()">&#x21bb;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(version, i) in versions"
              :key="i"
              :id="i"
              v-on:click="loadPhrases(version)"
            >
              <td class="align-middle">{{ version.version }}</td>
              <td class="align-middle">{{ datetimeString(version.date) }}</td>
              <td class="align-middle">{{ version.characters }}</td>
              <td class="align-middle">{{ version.lines }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getSRT(version.version)"
                >
                  {{ $t("Copla.Common.Buttons.Download") }}
                </button>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getTranscription(version.version)"
                >
                  {{ $t("Copla.Common.Buttons.Download") }}
                </button>
              </td>
              <td
                class="float-right reload"
                v-on:click.stop=""
                v-on:click="deleteVersion(version.version)"
              >
                <i class="fas fa-trash"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="border rounded secciones mt-4" v-if="editing">
          <h2 class="text-center">{{ $t("Copla.Views.Versions.Subtitle") }}</h2>
          <div class="form-group row mt-4">
            <label
              for="lines"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Lines") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="lines"
                id="lines"
                min="1"
                v-model="lines"
              />
            </div>
            <label
              for="characters"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Characters") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="characters"
                id="characters"
                min="1"
                max="120"
                v-model="characters"
              />
            </div>
            <div class=" col mr-3 mt-2 mt-xl-0">
              <button
                type="button"
                class="btn btn-success"
                v-on:click="sync(editingVersion)"
              >
                {{ $t("Copla.Common.Buttons.Update") }}
              </button>
            </div>
          </div>
          <table class="table table-sm table-striped table-dark mt-2">
            <thead>
              <tr>
                <th>{{ $t("Copla.Views.Versions.Table.Start") }}</th>
                <th>{{ $t("Copla.Views.Versions.Table.End") }}</th>
                <th>{{ $t("Copla.Views.Versions.Table.Original") }}</th>
                <th>{{ $t("Copla.Views.Versions.Table.Correction") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(phrase, i) in phrases" :key="i" :id="i">
                <td class="align-middle">{{ timeSub(phrase.offset, 0) }}</td>
                <td class="align-middle">
                  {{ timeSub(phrase.offset, phrase.duration) }}
                </td>
                <td class="align-middle text-left">{{ phrase.display }}</td>
                <td class="align-middle" style="width:50%;">
                  <textarea
                    v-model="phrasesModified[i].display"
                    style="width:100%;height:100%;"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            type="button"
            class="btn btn-success"
            v-on:click="sync(editingVersion)"
          >
            {{ $t("Copla.Common.Buttons.Update") }}
          </button>
        </div>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
import MainMenu from "../components/MainMenu";
import formatSubtitlesAPI from "../js/formatSubtitlesAPI";
import auxiliarFunctions from "../js/AuxiliarFunctions";
import ModalLoading from "../components/ModalLoading.vue";
export default {
  name: "Versions",
  components: {
    MainMenu,
    ModalLoading
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      showModalLoading: false,
      messageModal: "",
      versions: [],
      updateTable: 0,
      editing: false,
      editingVersion: -1,
      characters: 0,
      lines: 0,
      phrases: [],
      phrasesModified: [],
      fsAPI: new formatSubtitlesAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      error: ""
    };
  },
  methods: {
    showModal(status, message = "") {
      this.messageModal = message;
      this.showModalLoading = status;
    },
    deleteVersion: function(version) {
      if (confirm(this.$t("Copla.Common.Messages.Confirm"))) {
        this.showModal(true, this.$t("Copla.Common.Modal.Deleting"));
        let self = this;
        this.fsAPI
          .deleteVersion(self.$route.params.cafId, version)
          .then(response => {
            if (!response.error) {
              this.showModal(false);
              self.getVersions();
              self.updateTable = auxiliarFunctions.datetimeString(
                Date.now() / 1000
              );
              self.editing = false;
            } else {
              this.showModal(false);
              this.error = "Error: " + response.data;
            }
          });
      }
    },
    getTranscription(version) {
      this.showModal(true, this.$t("Copla.Common.Modal.Downloading"));
      let self = this;
      this.fsAPI
        .getTranscription(self.$route.params.cafId, version)
        .then(response => {
          if (!response.error) {
            auxiliarFunctions.downloadFile(
              response.data.data,
              "transcription.txt"
            );
            this.showModal(false);
          } else {
            this.showModal(false);
            this.error = "Error: " + response.data;
          }
        });
    },
    getSRT(version) {
      this.showModal(true, this.$t("Copla.Common.Modal.Downloading"));
      let self = this;
      this.fsAPI.getSRT(self.$route.params.cafId, version).then(response => {
        if (!response.error) {
          auxiliarFunctions.downloadFile(response.data.data, "subs.srt");
          this.showModal(false);
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    getVersions: function() {
      this.showModal(true, this.$t("Copla.Common.Modal.Loading"));
      let self = this;
      this.fsAPI.getVersions(self.$route.params.cafId).then(response => {
        if (!response.error) {
          this.showModal(false);
          self.versions = response.data.data;
          self.updateTable = auxiliarFunctions.datetimeString(
            Date.now() / 1000
          );
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    loadPhrases: function(version) {
      this.characters = version.characters;
      this.lines = version.lines;
      this.editingVersion = version.version;
      this.showModal(true, this.$t("Copla.Common.Modal.Loading"));
      let self = this;
      this.fsAPI
        .getPhrases(self.$route.params.cafId, version.version)
        .then(response => {
          if (!response.error) {
            this.showModal(false);
            self.phrases = response.data.data;
            self.phrasesModified = JSON.parse(JSON.stringify(self.phrases));
            self.editing = true;
          } else {
            this.showModal(false);
            this.error = "Error: " + response.data;
          }
        });
    },
    sync: function() {
      let self = this;
      self.showModal(true, this.$t("Copla.Common.Modal.Saving"));
      let params = {
        _phrases: this.phrasesModified,
        _lines: this.lines,
        _characters: this.characters
      };
      this.fsAPI
        .postVersion(params, self.$route.params.cafId, self.editingVersion)
        .then(response => {
          if (!response.error) {
            self.getVersions();
            self.editing = false;
          } else {
            this.showModal(false);
            this.error = "Error: " + response.data;
          }
        });
    },
    datetimeString: function(ts) {
      return auxiliarFunctions.datetimeString(ts);
    },
    timeSub: function(offset, duration) {
      if (duration < 0) {
        duration = 0;
      }
      return auxiliarFunctions.timeSub(offset, duration);
    }
  },
  mounted() {
    this.$route.params.cafId !== undefined
      ? this.getVersions()
      : this.$router.push("subtitles");
  }
};
</script>

<style scoped>
.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
